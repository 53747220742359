/* Modal */
const css = require('!sass-to-string!sass-loader!../styles.scss')

class TssModal {
  constructor(container) {
    const _ = this

    _.DOM = {
      wrapper: document.createElement('div'),
      dialog: document.createElement('div'),
      close: document.createElement('button'),
      iframe: document.createElement('iframe'),
      style: document.createElement('style')
    }

    _.DOM.wrapper.classList.add('tss-modal')
    _.DOM.dialog.classList.add('tss-modal__dialog')
    _.DOM.close.classList.add('tss-modal__close')
    _.DOM.iframe.classList.add('tss-modal__iframe')

    _.DOM.wrapper.appendChild(_.DOM.dialog)
    _.DOM.dialog.appendChild(_.DOM.close)

    _.DOM.style.innerHTML = css.default

    container.appendChild(_.DOM.wrapper)
    document.getElementsByTagName('head')[0].appendChild(_.DOM.style)

    _.DOM.close.addEventListener('click', () => _.close(), false)
  }
  open(url) {
    const _ = this

    _.DOM.dialog.appendChild(_.DOM.iframe)
    _.DOM.iframe.setAttribute('src', url)
    _.DOM.wrapper.classList.add('tss-modal--active')
    setTimeout(() => {
      _.DOM.wrapper.classList.add('tss-modal--visible')
    }, 100)
  }
  close() {
    const _ = this

    _.DOM.wrapper.classList.remove('tss-modal--visible')
    setTimeout(() => {
      _.DOM.wrapper.classList.remove('tss-modal--active')
      _.DOM.iframe.remove()
    }, 500)
  }
}

export default TssModal
